import React from 'react';
import { compose } from 'recompose';
import { Link } from 'gatsby'

import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import { UserListWithEditorAndDeleteUserNo } from '../components/Users';
import * as ROLES from '../constants/roles';
import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import HeadData from "../data/HeadData"

const NotLoggedInUsersPageBase = () => (
  <DashboardWrapper pageName='Manage Users'>
    <div className="relative md:pt-28 2xl:pt-32 pb-32 pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <div className='w-full max-w-screen-lg'>
          <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>Admin Dashboard</h2>
          <p className="text-base md:text-xl text-gray-800 mt-6">This Admin Page is accessible to every signed in ADMIN user.</p>
        </div>

        <div className='w-full max-w-sm mt-6'>
          <Link to='/add-users'>
            <button className='group relative w-full flex justify-center py-2 px-4 mb-8 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo active:bg-red-900 transition duration-150 ease-in-out'>
              Add New User
            </button>
          </Link>
        </div>

        <div className='w-full max-w-sm mt-6'>
          <Link to='/admin'>
            <button className='group relative w-full flex justify-center py-2 px-4 mb-8 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo active:bg-red-900 transition duration-150 ease-in-out'>
              View List of Users Who Logged In
            </button>
          </Link>
        </div>

        <hr/>

        <UserListWithEditorAndDeleteUserNo />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const NotLoggedInUsersPage = compose(
  withAuthorization(condition),
)(NotLoggedInUsersPageBase);

const NotLoggedIn = () => (
  <Layout>
    <HeadData title='NOT Logged In Users' />
    <NotLoggedInUsersPage />
  </Layout>
)

export default NotLoggedIn
